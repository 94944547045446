import React from "react";

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="logo-header">About</h1>
      </header>
    </div>
  );
}

export default About;
